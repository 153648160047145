<template>
    <div class="flex w-full h-screen items-center justify-center text-2xl">
       Добро пожаловать в личный кабинет
    </div>
</template>

<script>
export default {
    meta:{
        title:'Личный кабинет'
    },
    name: "Home"
}
</script>

<style scoped>

</style>